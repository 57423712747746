import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UserProfile from '../../utils/functions/UserProfile';
import { faGear, faHandshake, faHome, faSignOutAlt, faUsers, faBars, faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NavBar.module.scss';
import logo from '../../assets/images/LogoMainVision.png';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(localStorage.getItem('isMenuOpen') === 'true');

  useEffect(() => {
    localStorage.setItem('isMenuOpen', isMenuOpen.toString());
  }, [isMenuOpen]);

  const handleLogout = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await fetch('https://webservices.gfttech.com.br/api/v1/sair', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      localStorage.removeItem('token');
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const formatUserName = (name: string): string => {
    const nameParts = name.split(' ');
    if (nameParts.length > 2) {
      return `${nameParts[0]} ${nameParts[1]}`;
    }
    return name;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.navContainer}>
      <div className={`${styles.sidebar} ${isMenuOpen ? styles.open : styles.closed}`}>
        <div className={styles.header}>
          <img src={logo} alt="Logo" className={`${styles.logo}`} />
          <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className={styles.menuIcon} />
        </div>
        <div className={styles.content}>
          <NavLink to="/AdminGeneral" className={styles.menuItem}>
            <FontAwesomeIcon icon={faHome} className={styles.icon} />
            <span className={styles.text}>Home</span>
          </NavLink>
          <NavLink to="/AccessManagementPartner" className={styles.menuItem}>
            <FontAwesomeIcon icon={faHandshake} className={styles.icon} />
            <span className={styles.text}>Acessos/Parceiros</span>
          </NavLink>
          <NavLink to="/AccessManagementEmployee" className={styles.menuItem}>
            <FontAwesomeIcon icon={faUsers} className={styles.icon} />
            <span className={styles.text}>Acessos/Funcionários</span>
          </NavLink>
          <NavLink to="/PainelParceiro" className={styles.menuItem}>
            <FontAwesomeIcon icon={faHeartPulse} className={styles.icon} />
            <span className={styles.text}>Health Parceiro</span>
          </NavLink>
          <NavLink to="/Parameters" className={styles.menuItem}>
            <FontAwesomeIcon icon={faGear} className={styles.icon} />
            <span className={styles.text}>Parâmetros/Cadastro</span>
          </NavLink>
        </div>
        <div className={styles.footer}>
          <UserProfile>
            {userData => (
              <>
                <div className={styles.mobileFooterContent}>
                  <div className={styles.menuItem}>
                    Usuario Logado: {formatUserName(userData.name)}
                  </div>
                  <button className={styles.btnLogOut} onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className={styles.icon} />
                    <span className={styles.text}><b>Sair</b></span>
                  </button>
                </div>
              </>
            )}
          </UserProfile>
        </div>
      </div>

      {!isMenuOpen && (
        <div className={styles.menuToggle}>
          <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className={styles.menuIcon} />
        </div>
      )}

      <div className={`${styles.topBar} ${isMenuOpen ? styles.menuOpen : ''}`}>
        <UserProfile>
          {userData => (
            <div className={styles.userSection}>
              <span className={styles.userName}>{formatUserName(userData.name)}</span>
             
            </div>
          )}
          
        </UserProfile>
        <button className={styles.btnLogOut} onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} className={styles.icon} />
          <span className={styles.text}><b>Sair</b></span>
        </button>
      </div>
    </div>
  );
}

export default NavBar;
