import { Col, Form, Row } from "react-bootstrap";
import styles from '../Register.module.scss';
import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import axios from "axios";

interface PersonalDataFormProps {
  onFormDataChange: (newFormData: any) => void;
  formData: any;
}

interface Unit {
  trading_name: string;
  company_unit_id: number;  
}

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({ onFormDataChange, formData }) => {
  // Define a forma padrão para garantir que formData não seja indefinido
  const initialFormData = {
    employeeName: '',
    cpf: '',
    hasCnpj: false,
    cnpj: '',
    dateOfBirth: '',
    rg: '',
    residentialPhone: '',
    cellPhone: '',
    education: '',
    gender: '',
    maritalStatus: '',
    fatherName: '',
    motherName: '',
    voterId: '',
    workCard: '',
    pisNumber: '',
    hasChildren: false,
    numberOfChildren: 0,
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
    unit: ''
  };

  const [showCnpjInput, setShowCnpjInput] = useState(false);
  const [hasChildren, setHasChildren] = useState(formData?.hasChildren || false);
  const [cpfValido, setCpfValido] = useState(false);
  const [units, setUnits] = useState<Unit[]>([]);

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    const newFormData = {
      ...formData,
      [name]: newValue,
    };
    onFormDataChange(newFormData);
  };

  const handleHasChildrenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setHasChildren(newValue);
    const newFormData = { 
      ...formData, 
      hasChildren: newValue,
      numberOfChildren: newValue ? formData.numberOfChildren : 0 
    };
    onFormDataChange(newFormData);
  };

  const validarCPF = (cpf: any) => {
    // remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');
  
    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }
  
    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpf)) {
      return false;
    }
  
    // calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;
  
    // verifica o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digitoVerificador1) {
      return false;
    }
  
    // calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;
  
    // verifica o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digitoVerificador2) {
      return false;
    }
  
    // CPF válido
    return true;
  };

  const handleCpfBlur = () => {
    const cpfIsValid = validarCPF(formData.cpf);
    setCpfValido(cpfIsValid);
    if (cpfIsValid) {
      onFormDataChange({ ...formData, cpf: formData.cpf });
    }
  };

  useEffect(() => {
    const fetchAddressData = async () => {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${formData.zipCode}/json/`);
        const data = await response.json();
        if (!data.erro) {
          const newFormData = {
            ...formData,
            street: data.logradouro || '',
            neighborhood: data.bairro || '',
            city: data.localidade || '',
            state: data.uf || '',
          };
          onFormDataChange(newFormData);
        } else {
          alert('CEP não encontrado. Por favor, verifique e tente novamente.');
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    };
  
    if (formData.zipCode?.length === 8) {
      fetchAddressData();
    }
  }, [formData.zipCode, onFormDataChange]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get<Unit[]>("https://webservices.gfttech.com.br/api/v1/empresa/buscar-todas", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUnits(response.data ?? []);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };
    fetchUnits();
  }, []);

  const handleChangeCep = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCep = event.target.value;
    const newFormData = {
      ...formData,
      zipCode: newCep,
    };
    onFormDataChange(newFormData);
  };

  return (
    <>
      <div className='personalData mt-5'>
        <Form.Group as={Row} className="mb-3">
          <Col sm="12" md="5">
            <Form.Control
              type="text"
              name="employeeName"
              placeholder="Nome Completo*"
              onChange={handleChange}
              value={formData?.employeeName || ''}
              required
            />
          </Col>
          <Col sm="12" md="4">
            <Form.Control
                as={IMaskInput}
                mask="000.000.000-00"
                name="cpf"
                placeholder="CPF*"
                onChange={handleChange}
                onBlur={handleCpfBlur}
                value={formData?.cpf || ''}
                isInvalid={!cpfValido && formData?.cpf?.length === 14}
                isValid={cpfValido}
            />
          </Col>
          <Col sm="12" md="3">
            <div>
              <Form.Check
                inline
                label="Possui CNPJ"
                name="hasCnpj"
                type="checkbox"
                id="checkbox-cnpj"
                className={styles.customCheckboxLabel}
                onChange={(e) => setShowCnpjInput(e.target.checked)}
              />
            </div>
          </Col>
        </Form.Group>
        {showCnpjInput && (
          <Form.Group as={Row} className="mb-3">
            <Col sm="12" md="12">
              <Form.Control
                type="text"
                name="cnpj"
                as={IMaskInput}
                value={formData?.cnpj || ''}
                onChange={handleChange}
                mask="00.000.000/0000-00"
                placeholder="CNPJ"
              />
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row} className="mb-3">
          <Col sm="12" md="3">
            <Form.Control
               type="date"
               name="dateOfBirth"
               value={formData?.dateOfBirth || ''}
               onChange={handleChange}
               required
               placeholder="Data de Nascimento"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              name="rg"
              value={formData?.rg || ''}
              onChange={handleChange}
              placeholder="RG"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              as={IMaskInput}
              name="residentialPhone"
              mask="(00)0000-0000"
              value={formData?.residentialPhone || ''}
              onChange={handleChange}
              placeholder="Telefone Fixo"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              as={IMaskInput}
              name="cellPhone"
              mask="(00)00000-0000"
              value={formData?.cellPhone || ''}
              onChange={handleChange}
              required
              placeholder="Celular/Whatsapp"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm="12" md="3">
            <Form.Select
              aria-label="Selecione o genero do colaborador"
              value={formData?.gender || ''}
              name="gender"
              onChange={handleChange}
            >
              <option>Gênero*</option>
              <option value="Feminino">Feminino</option>
              <option value="Masculino">Masculino</option>
            </Form.Select>
          </Col>
          <Col sm="12" md="3">
            <Form.Select 
              aria-label="Default select example"
              name="maritalStatus"
              value={formData?.maritalStatus || ''}
              onChange={handleChange}
            >
              <option>Estado Civil*</option>
              <option value="Casado">Casado</option>
              <option value="Divorciado">Divorciado</option>
              <option value="Separado">Separado</option>
              <option value="Solteiro">Solteiro</option>
              <option value="Viuvo">Viúvo</option>
            </Form.Select>
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              required
              name="fatherName"
              value={formData?.fatherName || ''}
              onChange={handleChange}
              placeholder="Nome do Pai*"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              name="motherName"
              value={formData?.motherName || ''}
              onChange={handleChange}
              required
              placeholder="Nome da Mãe*"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              name="pisNumber"
              value={formData?.pisNumber || ''}
              onChange={handleChange}
              placeholder="PIS/NIS"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              name="workCard"
              value={formData?.workCard || ''}
              onChange={handleChange}
              placeholder="Carteira de Trabalho"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              name="voterId"
              value={formData?.voterId || ''}
              onChange={handleChange}
              placeholder="Título de Eleitor"
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Select 
              name="education"
              aria-label="Selecione o nivel de educação do colaborador"
              value={formData?.education || ''}
              onChange={handleChange}
            >
              <option>Formação*</option>
              <option value="Ensino Médio Completo">Ensino Médio Completo</option>
              <option value="Ensino Superior Incompleto">Ensino Superior Incompleto</option>
              <option value="Ensino Superior Completo">Ensino Superior Completo</option>
              <option value="Mestrado">Mestrado</option>
              <option value="Doutorado">Doutorado</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-4 mb-3">
          <Col sm="12">
            <Form.Check
              type="checkbox"
              name="hasChildren"
              label="Tem filhos?"
              checked={hasChildren}
              onChange={handleHasChildrenChange}
            />
          </Col>
        </Form.Group>
        {hasChildren && (
          <Form.Group as={Row} className="mb-3">
            <Col sm="12">
              <Form.Label>Quantidade de filhos:</Form.Label>
              <Form.Control
                type="text"
                name="numberOfChildren"
                value={formData?.numberOfChildren || 0}
                onChange={handleChange}
                min={0}
              />
            </Col>
          </Form.Group>
        )}

        <Form.Group as={Row} className="mt-3">
          <Col sm="12" md="12">
            <Form.Select 
              aria-label="Selecione a unidade do colaborador"
              value={formData?.unit || ''}
              name="unit"
              onChange={handleChange}
            >
              <option value="">Selecione a Unidade</option>
              {Array.isArray(units) && units.sort((a, b) => {
                const nameA = a.trading_name.toUpperCase();
                const nameB = b.trading_name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }).map((unit) => (
                <option key={unit.company_unit_id} value={unit.company_unit_id}>
                  {unit.trading_name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        
        <Form.Group as={Row} className="mb-3 mt-4">
          <span className="mb-4"> 
            <b>Endereço do Funcionário:</b>
          </span>
          <Col sm="12" md="4">
            <Form.Control
              type="text"
              name="zipCode"
              placeholder="CEP*"
              value={formData?.zipCode || ''}
              onChange={handleChangeCep}
              required
            />
          </Col>
          <Col sm="12" md="6">
            <Form.Control
              type="text"
              name="logradouro"
              placeholder="Logradouro*"
              value={formData?.street || ''}
              onChange={handleChange}
            />
          </Col>
          <Col sm="12" md="2">
            <Form.Control
              type="text"
              placeholder="N*"
              name="number"
              value={formData?.number || ''}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm="12" md="4">
            <Form.Control
              type="text"
              name="bairro"
              value={formData?.neighborhood || ''}
              placeholder="Bairro*"
              onChange={handleChange}
            />
          </Col>
          <Col sm="12" md="6">
            <Form.Control
              type="text"
              name="localidade"
              value={formData?.city || ''}
              onChange={handleChange}
              placeholder="Cidade*"
            />
          </Col>
          <Col sm="12" md="2">
            <Form.Control
              type="text"
              name="uf"
              value={formData?.state || ''}
              onChange={handleChange}
              placeholder="UF*"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm="12" md="12">
            <Form.Control
              type="text"
              name="complement"
              value={formData?.complement || ''}
              onChange={handleChange}
              placeholder="Complemento"
            />
          </Col>
        </Form.Group>
      </div>
    </>
  );
};

export default PersonalDataForm;

