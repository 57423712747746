import { Modal, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import requestClient from '../../../../requestClient/request-cliente';

interface UnitModalProps {
  onClose: () => void;
}

const UnitModal: React.FC<UnitModalProps> = ({ onClose }) => {
  const [unitData, setUnitData] = useState({
    name: '',
    trading_name: '',
    type: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
    phone: '',
    email: ''
  });
  const [cep, setCep] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setUnitData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCep(value);

    if (value.length === 8) {
      try {
        const response = await fetch(`https://brasilapi.com.br/api/cep/v2/${value}`);
        const data = await response.json();

        if (data && !data.error) {
          setUnitData((prevState) => ({
            ...prevState,
            street: data.street,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            country: 'Brasil'
          }));
        } else {
          setErrorMessage('CEP não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar o CEP:', error);
        setErrorMessage('Erro ao buscar o CEP. Por favor, tente novamente.');
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await requestClient(
        'post', 
        'https://webservices.gfttech.com.br/api/v1/empresa/cadastrar', 
        JSON.stringify(unitData), 
        headers
      );

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('Unidade cadastrada com sucesso.');
        setUnitData({
          name: '',
          trading_name: '',
          type: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
          phone: '',
          email: ''
        });
        setCep('');
      } else {
        setErrorMessage('Erro ao cadastrar a Unidade. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Erro ao cadastrar a Unidade. Por favor, tente novamente.');
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Criar nova Unidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Row>
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Nome da Unidade"
                  name="name"
                  value={unitData.name}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Nome Fantasia"
                  name="trading_name"
                  value={unitData.trading_name}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="CEP"
                  name="cep"
                  value={cep}
                  onChange={handleCepChange}
                  required
                />
              </Col>
              <Col md={6}>
                <Form.Select
                  name="type"
                  value={unitData.type}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Selecione o Tipo</option>
                  <option value="1">FILIAL</option>
                  <option value="2">EXTERNO</option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Rua"
                  name="street"
                  value={unitData.street}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Número"
                  name="number"
                  value={unitData.number}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Complemento"
                  name="complement"
                  value={unitData.complement}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Bairro"
                  name="neighborhood"
                  value={unitData.neighborhood}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Cidade"
                  name="city"
                  value={unitData.city}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Estado"
                  name="state"
                  value={unitData.state}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="País"
                  name="country"
                  value={unitData.country}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Telefone"
                  name="phone"
                  value={unitData.phone}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={unitData.email}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
          </Form.Group>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={onClose} className="me-2">
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UnitModal;
