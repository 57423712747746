import { Modal, Button, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import styles from '../../Register.module.scss';

interface RoleModalProps {
  onClose: () => void;
}

const RoleModal: React.FC<RoleModalProps> = ({ onClose }) => {
  const [RoleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await fetch('https://webservices.gfttech.com.br/api/v1/permissao-papel/papel/cadastrar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ role_name: RoleName, description }),
      });

      if (response.status === 201) {
        setSuccess(true);
        setRoleName('');
        setDescription('');
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const responseData = await response.json();
          throw new Error(responseData.message || 'Erro ao criar a Função');
        } else {
          const responseText = await response.text();
          throw new Error(responseText || 'Erro ao criar a Função');
        }
      }
    } catch (error: any) {
      console.error('Erro ao criar a Função:', error);
      setError(error.message || 'Erro ao criar a Função. Por favor, tente novamente.');
    }
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar nova Função</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && (
          <div className="alert alert-success" role="alert">
            Função criada com sucesso!
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="roleNameInput">
            <Form.Control
              type="text"
              placeholder="Nome da Função"
              value={RoleName}
              onChange={(e) => setRoleName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="descriptionInput">
            <Form.Control
              type="text"
              placeholder="Descrição"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <div className='mt-4' style={{textAlign: 'center'}}>
          </div>
          <div className={styles.actionButtons}>
            <Button variant="secondary" onClick={onClose} className="me-2">
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RoleModal;
