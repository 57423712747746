import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styles from './Login.module.scss';
import logo from '../../assets/images/logoDefaultVision.png';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import ButtonPrimary from '../../components/ButtonPrimary';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const isFormValid = () => {
    return login.trim() !== '' && password.trim() !== '';
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://webservices.gfttech.com.br/api/v1/logar', {
        email: login,
        password: password
      });

      if (response.data.authorization) {
        localStorage.setItem('token', response.data.authorization);
        localStorage.setItem('isMenuOpen', 'true');
        window.location.href = '/AdminGeneral';
      } else {
        setError('Token de autorização não encontrado na resposta.');
      }
    } catch (error) {
      setError('Erro ao realizar login. Por favor, verifique suas credenciais e tente novamente.');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isFormValid()) {
      handleLogin();
    }
  };

  return (
    <div className={styles.mainSectionLogin}>
      <div className={`${styles.containerLogin} p-5`}>
        <img src={logo} alt="Logo" />
        <div className={styles.inputWithIcon}>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Email"
              className="form-control"
              value={login}
              onChange={handleLoginChange}
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Senha"
              className="form-control"
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyPress}
            />
            <div className={styles.icon} onClick={togglePasswordVisibility}>
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </div>
          </div>
        </div>
        {error && <p className="text-danger text-center mt-4">{error}</p>}
        {/*<Form.Check
          inline
          label="Permanecer conectado"
          name="isConected"
          type="checkbox"
          id={`inline-checkbox-1`}
          className={`${styles.customCheckboxLabel} text-truncate mb-4`}
        />*/}
        <ButtonPrimary
          content="Entrar"
          onClick={handleLogin}
          disabled={!isFormValid()}
        />
        <div className="row d-flex justify-content-between mt-4">
          <div className="col-sm-6">
            <span className={`${styles.loginSpan} text-truncate`}>Esqueci minha senha</span>
          </div>
          <div className="col-sm-6">
            <span className={`${styles.loginSpan} text-truncate`}>Quero ser parceiro!</span>
          </div>
        </div>
        <div className="row mt-5">
          <button className={`${styles.btnPolicy} mt-4`}>Política de Privacidade</button>
        </div>
      </div>
    </div>
  );
}
