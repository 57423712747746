import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditPermissionModalProps {
  PermissionId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const PermissionEditModal: React.FC<EditPermissionModalProps> = ({ PermissionId, onClose, onUpdate, onError }) => {
  const [PermissionName, setPermissionName] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (PermissionId) {
      fetchPermissionName();
    }
  }, [PermissionId]);

  const fetchPermissionName = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !PermissionId) {
        throw new Error('Token or Permission ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `https://webservices.gfttech.com.br/api/v1/permissao-papel/permissao/atualizar/${PermissionId}`, null, headers);

      if (response.status === 200) {
        setPermissionName(response.result.name);
      } else {
        throw new Error('Erro ao buscar o nome do Permissão. Por favor, tente novamente.');
      }
    } catch (error:any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar o nome do Permissão. Por favor, tente novamente.');
    }
  };

  const handlePermissionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPermissionName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !PermissionId) {
        throw new Error('Token or Permission ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `https://webservices.gfttech.com.br/api/v1/dados-bancario/Permissão/atualizar/${PermissionId}`, { name: PermissionName }, headers);

      if (response.status === 200) {
        onUpdate();
        setUpdateSuccess(true); // Definir sucesso como true para exibir a mensagem
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar a Permissão. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar a Permissão. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar a Permissão. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Permissão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Permissão atualizada com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPermissionName" className='mb-5'>
            <Form.Control type="text" placeholder="Digite o nome da Permissão" value={PermissionName} onChange={handlePermissionNameChange} required />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PermissionEditModal;