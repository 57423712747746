import React, { useEffect, useState } from 'react';
import { Modal, ListGroup, Spinner, Container, Table, Col, Tab, Row } from 'react-bootstrap';
import styles from './Bonus.module.scss';

interface BonusProps {
  codPartner: string;
  show: boolean;
  handleClose: () => void;
}

interface BonusData {
  id: string;
  meta1: number;
  meta2: number;
  meta3: number;
  bonus1: number;
  bonus2: number;
  bonus3: number;
  cond1: string;
  cond2: string;
  cond3: string;
  cond4: string;
  cond5: string;
  inicioVig: string;
  finalVig: string;
}

function formatCurrency(value: number | undefined): string {
  if (value === undefined || isNaN(value)) {
    return 'R$ 0,00';
  }
  return `R$ ${value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/,/g, ',')}`;
}

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR');
}

export default function Bonus({ codPartner, show, handleClose }: BonusProps) {
  const [bonusData, setBonusData] = useState<BonusData[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchBonusData() {
      try {
        const response = await fetch(`https://bi.gfttech.com.br/api/v1/atendimento/bonus?codProdutorVenda=${codPartner}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: BonusData[] = await response.json();
        const processedData = data.map((item, index) => ({
          ...item,
          id: `bonus-${index}`,
        }));
        setBonusData(processedData);
        if (processedData.length > 0) {
          setActiveKey(processedData[0].id);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados de bônus:', error);
        setIsLoading(false);
      }
    }

    fetchBonusData();
  }, [codPartner]);

  if (isLoading) {
    return (
      <Modal show={show} onHide={handleClose} size="lg" className={styles.modalStyles}>
        <Modal.Header closeButton>
          <Modal.Title>Listagem de bônus</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" className={styles.modalStyles}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className={styles.headerBonusList}>Listagem de bônus</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.sectionBonusList}>
        <Container className='mt-4 mb-5'>
          <Tab.Container id="list-group-tabs-example" activeKey={activeKey} onSelect={(key) => setActiveKey(key as string)}>
            <Row>
              <Col sm={4}>
                <ListGroup>
                  {bonusData.map((bonus) => (
                    bonus.bonus1 !== 0 && (
                      <ListGroup.Item
                        key={bonus.id}
                        action
                        eventKey={bonus.id}
                        className={activeKey === bonus.id ? `${styles.bonusItem} ${styles.active}` : styles.bonusItem}
                      >
                        {bonus.bonus1}%
                      </ListGroup.Item>
                    )
                  ))}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content className={styles.tabContent}>
                  {bonusData.map((bonus) => (
                    <Tab.Pane key={bonus.id} eventKey={bonus.id}>
                      <Table striped bordered hover>
                        <tbody>
                          {bonus.meta1 !== 0 && (
                            <tr className={styles.bonusItem}>
                              <td className={styles.bold}>Meta 1</td>
                              <td>{formatCurrency(bonus.meta1)}</td>
                            </tr>
                          )}
                          {bonus.meta2 !== 0 && (
                            <tr>
                              <td className={styles.bold}>Meta 2</td>
                              <td>{formatCurrency(bonus.meta2)}</td>
                            </tr>
                          )}
                          {bonus.meta3 !== 0 && (
                            <tr>
                              <td className={styles.bold}>Meta 3</td>
                              <td>{formatCurrency(bonus.meta3)}</td>
                            </tr>
                          )}
                          {bonus.bonus1 !== 0 && (
                            <tr>
                              <td className={styles.bold}>Bônus 1</td>
                              <td>{bonus.bonus1}%</td>
                            </tr>
                          )}
                          {bonus.bonus2 !== 0 && (
                            <tr>
                              <td className={styles.bold}>Bônus 2</td>
                              <td>{bonus.bonus2}%</td>
                            </tr>
                          )}
                          {bonus.bonus3 !== 0 && (
                            <tr>
                              <td className={styles.bold}>Bônus 3</td>
                              <td>{bonus.bonus3}%</td>
                            </tr>
                          )}
                          {bonus.cond1 && (
                            <tr>
                              <td className={styles.bold}>Condição 1</td>
                              <td>{bonus.cond1}</td>
                            </tr>
                          )}
                          {bonus.cond2 && (
                            <tr>
                              <td className={styles.bold}>Condição 2</td>
                              <td>{bonus.cond2}</td>
                            </tr>
                          )}
                          {bonus.cond3 && (
                            <tr>
                              <td className={styles.bold}>Condição 3</td>
                              <td>{bonus.cond3}</td>
                            </tr>
                          )}
                          {bonus.cond4 && (
                            <tr>
                              <td className={styles.bold}>Condição 4</td>
                              <td>{bonus.cond4}</td>
                            </tr>
                          )}
                          {bonus.cond5 && (
                            <tr>
                              <td className={styles.bold}>Condição 5</td>
                              <td>{bonus.cond5}</td>
                            </tr>
                          )}
                          {bonus.inicioVig && (
                            <tr>
                              <td className={styles.bold}>Início da Vigência</td>
                              <td>{formatDate(bonus.inicioVig)}</td>
                            </tr>
                          )}
                          {bonus.finalVig && (
                            <tr>
                              <td className={styles.bold}>Final da Vigência</td>
                              <td>{formatDate(bonus.finalVig)}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
