import { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { IMaskInput } from 'react-imask';
import axios from 'axios';

interface FinancialDataProps {
  formData: FinancialFormData; // Alteração aqui para receber a prop formData
  onFormDataChange: (formData: FinancialFormData) => void;
}

interface FinancialFormData {
  account_owner_type: string;
  selectedAccountType: string;
  bank_id: string;
  agency: string;
  agencyDigit: string;
  accountNumber: string;
  accountDigit: string;
  branch: string;
  account_holder: string;
  owner_tax_id: string;
  pix_key: string;
}

interface BankOption {
  value: string;
  label: string;
}

const FinancialData: React.FC<FinancialDataProps> = ({ formData, onFormDataChange }) => {
  const [bankOptions, setBankOptions] = useState<BankOption[]>([]);
  const [selectedBank, setSelectedBank] = useState<BankOption | null>(null);

  useEffect(() => {
    const fetchBanks = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://webservices.gfttech.com.br/api/v1/dados-bancario/banco/buscar-todos', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const banks = response.data.map((bank: any) => ({
          value: bank.id_bank,
          label: `${bank.number_bank} - ${bank.name}`
        }));
        setBankOptions(banks);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
  }, []);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    const newFormData = {
      ...formData,
      [name as string]: value as string,
    };
    onFormDataChange(newFormData);
  };

  const handleSelectChange = (selectedOption: BankOption | null) => {
    setSelectedBank(selectedOption);
    const newFormData = {
      ...formData,
      bank_id: selectedOption ? selectedOption.value : "",
    };
    onFormDataChange(newFormData);
  };

  const handleMaskedChange = (name: string, value: string) => {
    const newFormData = {
      ...formData,
      [name]: value,
    };
    onFormDataChange(newFormData);
  };

  return (
    <>
      <div className="mt-5">
        <Form>
          <Row className="mb-3">
            <Col sm="12" md="6">
              <Form.Select
                aria-label="Default select example"
                name="account_owner_type"
                value={formData.account_owner_type}
                onChange={handleChange}
              >
                <option>Tipo da Conta*</option>
                <option value="PF">Conta PF</option>
                <option value="PJ">Conta PJ</option>
              </Form.Select>
            </Col>
            <Col sm="12" md="6">
              <Select
                placeholder="Banco*"
                options={bankOptions}
                value={selectedBank}
                onChange={handleSelectChange}
                isClearable
                filterOption={(option, inputValue) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.value.toString().includes(inputValue)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <Form.Control
                type="text"
                required
                placeholder="Agência"
                name="branch"
                value={formData.branch}
                onChange={handleChange}
              />
            </Col>
            <Col sm="12" md="8">
              <Form.Control
                type="text"
                required
                placeholder="Conta Corrente (com dv)"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="12" md="4">
              <Form.Control
                type="text"
                required
                placeholder="Titular da Conta"
                name="account_holder"
                value={formData.account_holder}
                onChange={handleChange}
              />
            </Col>
            <Col sm="12" md="4">
              {formData.account_owner_type === "PF" ? (
                <IMaskInput
                  mask="000.000.000-00"
                  className="form-control"
                  required
                  placeholder="CPF"
                  name="owner_tax_id"
                  value={formData.owner_tax_id}
                  onAccept={(value: any) => handleMaskedChange("owner_tax_id", value)}
                />
              ) : (
                <IMaskInput
                  mask="00.000.000/0000-00"
                  className="form-control"
                  required
                  placeholder="CNPJ"
                  name="owner_tax_id"
                  value={formData.owner_tax_id}
                  onAccept={(value: any) => handleMaskedChange("owner_tax_id", value)}
                />
              )}
            </Col>
            <Col sm="12" md="4">
              <Form.Control
                type="text"
                required
                placeholder="Chave Pix"
                name="pix_key"
                value={formData.pix_key}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FinancialData;
