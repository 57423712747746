import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

interface UnitConfirmExcludeProps {
  UnitId: number | null;
  onClose: () => void;
  onDelete: () => void;
  error: string;
}

const UnitConfirmExclude: React.FC<UnitConfirmExcludeProps> = ({ UnitId, onClose, onDelete, error }) => {
  const [isDeleted, setIsDeleted] = useState(false); // state para controlar se a Unidade foi excluída com sucesso

  if (!UnitId) return null; // se UnitId for nulo, não preciso renderizar o modal

  // Função para enviar a solicitação de exclusão da Unidade
  const deleteUnit = async () => {
    try {
      const token = localStorage.getItem('token'); // Obter o token do localStorage

      if (!token) {
        throw new Error('Token não encontrado');
      }

      // Configura os headers com o token de autenticação
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Faz a requisição DELETE para o endpoint correspondente
      await axios.delete(`https://webservices.gfttech.com.br/api/v1/empresa/deletar/${UnitId}`, { headers });

      // Se a exclusão for bem-sucedida, define isDeleted como true
      setIsDeleted(true);
      console.log(UnitId);

      // Chama a função onDelete para atualizar o estado do componente pai, se necessário
      onDelete();
    } catch (error) {
      // Se ocorrer algum erro, você pode lidar com ele aqui
      console.error('Erro ao excluir a Unidade:', error);
    }
  };

  // se a Unidade foi excluída com sucesso, exibir mensagem e ocultar botões
  if (isDeleted) {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Unidade excluída com sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir esta Unidade?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteUnit}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default UnitConfirmExclude;
