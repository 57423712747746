import { Modal, Button, Form, Alert } from 'react-bootstrap';
import React, { useState } from 'react';
import requestClient from '../../../../requestClient/request-cliente';

interface PermissionModalProps {
  onClose: () => void;
}

const PermissionModal: React.FC<PermissionModalProps> = ({ onClose }) => {
  const [PermissionName, setPermissionName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('post', 'https://webservices.gfttech.com.br/api/v1/permissao-papel/permissao/cadastrar', { permission_name: PermissionName }, headers);

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('Permissão cadastrada com sucesso.');
        setPermissionName('');
      } else {
        setErrorMessage('Erro ao cadastrar a Permissão. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Erro ao cadastrar a Permissão. Por favor, tente novamente.');
    }
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar novo Permissão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              placeholder="Nome do Permissão"
              value={PermissionName}
              onChange={(e) => setPermissionName(e.target.value)}
              required
            />
          </Form.Group>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={onClose} className="me-2">
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PermissionModal;
