import { Card, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import styles from './AvailableConventions.module.scss';

interface Option {
  value: string;
  label: string;
}

interface Convention {
  banco: string;
  convenio: string;
}

interface AvailableConventionsProps {
  initialUF: string;
}

const ufs: Option[] = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];

export default function AvailableConventions({ initialUF }: AvailableConventionsProps) {
  const [locaisOptions, setLocaisOptions] = useState<Convention[]>([]);
  const [governoOptions, setGovernoOptions] = useState<Convention[]>([]);
  const [locaisSelected, setLocaisSelected] = useState<Option | null>(ufs.find(uf => uf.value === initialUF) || null);
  const [governoSelected, setGovernoSelected] = useState<Option | null>(ufs.find(uf => uf.value === initialUF) || null);

  useEffect(() => {
    if (locaisSelected) {
      fetch(`https://bi.gfttech.com.br/api/v1/atendimento/convenios?nomConvenio=- ${locaisSelected.value}`)
        .then(response => response.json())
        .then(data => setLocaisOptions(data))
        .catch(error => console.error('Error fetching locais data:', error));
    }
  }, [locaisSelected]);

  useEffect(() => {
    if (governoSelected) {
      fetch(`https://bi.gfttech.com.br/api/v1/atendimento/convenios?nomConvenio=gov_${governoSelected.value}`)
        .then(response => response.json())
        .then(data => setGovernoOptions(data))
        .catch(error => console.error('Error fetching governo data:', error));
    }
  }, [governoSelected]);

  useEffect(() => {
    // aciona a busca para "Governo" se a UF inicial estiver definida
    if (initialUF) {
      setLocaisSelected(ufs.find(uf => uf.value === initialUF) || null);
      setGovernoSelected(ufs.find(uf => uf.value === initialUF) || null);
    }
  }, [initialUF]);

  return (
    <div className={`${styles.availableConventionsSection}`}>
      <h3 className={`${styles.underlineTitle} mb-5`} >Convênios Disponíveis</h3>
      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Card>
              <Card.Header>
                <h4>Locais</h4>
              </Card.Header>
              <Card.Body>
                <Select
                  className="mb-3"
                  placeholder="Selecione a UF"
                  options={ufs}
                  value={locaisSelected}
                  onChange={setLocaisSelected}
                />
                <div className={styles.tableContainer}>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Convênio</th>
                        <th>Banco</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locaisOptions.map((item, index) => (
                        <tr key={index}>
                          <td>{item.convenio}</td>
                          <td>{item.banco}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Card>
              <Card.Header>
                <h4>Governo</h4>
              </Card.Header>
              <Card.Body>
                <Select
                  className="mb-3"
                  placeholder="Selecione a UF"
                  options={ufs}
                  value={governoSelected}
                  onChange={setGovernoSelected}
                />
                <div className={styles.tableContainer}>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Convênio</th>
                        <th>Banco</th>
                      </tr>
                    </thead>
                    <tbody>
                      {governoOptions.map((item, index) => (
                        <tr key={index}>
                          <td>{item.convenio}</td>
                          <td>{item.banco}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}
