import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditUnitModalProps {
  UnitId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const UnitEditModal: React.FC<EditUnitModalProps> = ({ UnitId, onClose, onUpdate, onError }) => {
  const [unitData, setUnitData] = useState<any>({});
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (UnitId) {
      fetchUnitData(UnitId);
    }
  }, [UnitId]);

  const fetchUnitData = async (id: number) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `https://webservices.gfttech.com.br/api/v1/empresa/buscar-usuario/${id}`, null, headers);

      if (response.status === 200 && response.result && response.result.information) {
        setUnitData(response.result.information);
      } else {
        throw new Error('Erro ao buscar dados da Unidade. Por favor, tente novamente.');
      }
    } catch (error: any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar dados da Unidade. Por favor, tente novamente.');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUnitData({ ...unitData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !UnitId) {
        throw new Error('Token or Unit ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `https://webservices.gfttech.com.br/api/v1/empresa/atualizar/${UnitId}`, unitData, headers);

      if (response.status === 201) {
        onUpdate();
        setUpdateSuccess(true);
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar a Unidade. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar a Unidade. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar a Unidade. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Editar Unidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Unidade atualizada com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formUnitName" className='mb-3'>
                <Form.Label>Nome da Unidade</Form.Label>
                <Form.Control type="text" name="name" value={unitData.name || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formTradingName" className='mb-3'>
                <Form.Label>Nome Fantasia</Form.Label>
                <Form.Control type="text" name="trading_name" value={unitData.trading_name || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formType" className='mb-3'>
                <Form.Label>Tipo</Form.Label>
                <Form.Control type="text" name="type" value={unitData.type || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formStreet" className='mb-3'>
                <Form.Label>Rua</Form.Label>
                <Form.Control type="text" name="street" value={unitData.street || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNumber" className='mb-3'>
                <Form.Label>Número</Form.Label>
                <Form.Control type="text" name="number" value={unitData.number || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formComplement" className='mb-3'>
                <Form.Label>Complemento</Form.Label>
                <Form.Control type="text" name="complement" value={unitData.complement || ''} onChange={handleInputChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNeighborhood" className='mb-3'>
                <Form.Label>Bairro</Form.Label>
                <Form.Control type="text" name="neighborhood" value={unitData.neighborhood || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formCity" className='mb-3'>
                <Form.Label>Cidade</Form.Label>
                <Form.Control type="text" name="city" value={unitData.city || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formState" className='mb-3'>
                <Form.Label>Estado</Form.Label>
                <Form.Control type="text" name="state" value={unitData.state || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formZipCode" className='mb-3'>
                <Form.Label>CEP</Form.Label>
                <Form.Control type="text" name="zip_code" value={unitData.zip_code || ''} onChange={handleInputChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formCountry" className='mb-3'>
                <Form.Label>País</Form.Label>
                <Form.Control type="text" name="country" value={unitData.country || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhone" className='mb-3'>
                <Form.Label>Telefone</Form.Label>
                <Form.Control type="text" name="phone" value={unitData.phone || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formEmail" className='mb-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" value={unitData.email || ''} onChange={handleInputChange} required />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UnitEditModal;
