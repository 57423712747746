import { Modal, Button, Form, Row, Col, Nav, Alert } from 'react-bootstrap';
import React, { useState } from 'react';
import styles from '../Register.module.scss';
import stylesDefault from '../../../styles/contentTemplate.module.scss';
import PersonalDataForm from './PersonalDataForm';
import ContractualDataForm from './ContractualDataForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faFileContract, faLockOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import FinancialData from './FinancialData';
import CreationParameters from './CreationParameters';

interface EmployeeModalProps {
  onClose: () => void;
}

const CreateEmployeeModal: React.FC<EmployeeModalProps> = ({ onClose }) => {
  const [formData, setFormData] = useState<any>({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [activeTab, setActiveTab] = useState("link-1");

  const handleFormDataChange = (newFormData: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      ...newFormData,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token não encontrado no localStorage');
      return;
    }

    const payload = {
      name: formData.employeeName,
      date_of_birth: formData.dateOfBirth,
      gender: formData.gender,
      marital_status: formData.maritalStatus,
      mother_name: formData.motherName,
      father_name: formData.fatherName,
      education: formData.education,
      number_of_children: formData.numberOfChildren,
      cpf: formData.cpf,
      rg: formData.rg,
      cnpj: formData.cnpj,
      residential_address: `${formData.street}, ${formData.number} - ${formData.neighborhood}`,
      residential_phone: formData.residentialPhone,
      cellphone: formData.cellPhone,
      date_of_hiring: formData.dateOfHiring,
      salary: formData.salary,
      contract_type: formData.selectedContractType,
      street: formData.street,
      number: formData.number,
      complement: formData.complement,
      neighborhood: formData.neighborhood,
      city: formData.city,
      state: formData.state,
      zip_code: formData.zipCode,
      country: formData.country,
      id_position: formData.position_id,
      unit_id: formData.unit,
      department_id: formData.department_id,
      role_id: formData.role_id,
      branch: formData.branch,
      account_number: formData.accountNumber,
      account_holder: formData.account_holder,
      bank_id: formData.bank_id,
      account_owner_type: formData.account_owner_type,
      owner_tax_id: formData.owner_tax_id,
      pix_key: formData.pix_key,
    };

    try {
      const response = await fetch('https://webservices.gfttech.com.br/api/v1/funcionario/v3/cadastrar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados');
      }

      const responseData = await response.json();
      console.log('Resposta do servidor:', responseData);

      if (response.ok) {
        setShowSuccessMessage(true);
        onClose();
        alert('Funcionário criado com sucesso!');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error('Erro:', error);
      console.log('dados enviados: ' + JSON.stringify(payload));
      setShowErrorMessage(true);
    }
  };

  const handleTabChange = (eventKey: any) => {
    setActiveTab(eventKey);
  };

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "link-1":
        return <PersonalDataForm formData={formData} onFormDataChange={handleFormDataChange} />;
      case "link-2":
        return <CreationParameters formData={formData} onFormDataChange={handleFormDataChange} />;
      case "link-3":
        return <ContractualDataForm initialFormData={formData} onFormDataChange={handleFormDataChange} />;
      case "link-4":
        return <FinancialData formData={formData} onFormDataChange={handleFormDataChange} />;
      default:
        return null;
    }
  };

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar novo Funcionário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Nav variant="tabs" className='mt-4' activeKey={activeTab} onSelect={handleTabChange}>
          <Nav.Item>
            <Nav.Link eventKey="link-1">
              <FontAwesomeIcon icon={faUser} className='me-1' /> Dados Pessoais
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="link-2">
              <FontAwesomeIcon icon={faLockOpen} className='me-1' /> Perfil/Permissões
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="link-3">
              <FontAwesomeIcon icon={faFileContract} className='me-1' /> Dados Contratuais
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="link-4">
              <FontAwesomeIcon icon={faDollarSign} className='me-1' /> Dados Financeiros
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <form onSubmit={handleSubmit}>
          {renderActiveComponent()}
          <div className={styles.actionButtons}>
            <Button variant="secondary" onClick={onClose} className="me-2">
              Fechar
            </Button>
            <Button variant="primary" type="submit" className={stylesDefault.buttonDefault}>
              Salvar
            </Button>
          </div>
        </form>

        <Alert variant="danger" show={showErrorMessage} className="mt-3">
          Ocorreu um erro ao salvar. Entre em contato com o administrador.
        </Alert>

      </Modal.Body>
    </Modal>
  );
};

export default CreateEmployeeModal;