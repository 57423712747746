import { Modal, Button, Form, Alert } from 'react-bootstrap';
import React, { useState } from 'react';
import requestClient from '../../../../requestClient/request-cliente';

interface PositionModalProps {
  onClose: () => void;
}

const PositionModal: React.FC<PositionModalProps> = ({ onClose }) => {
  const [PositionName, setPositionName] = useState('');
  const [PositionDescription, setPositionDescription] = useState('');
  const [TypePosition, setTypePosition] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('post', 'https://webservices.gfttech.com.br/api/v1/posicao/cadastrar', 
        { 
          position_name: PositionName, 
          description: PositionDescription,
          type_position: TypePosition
        }, headers);

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('Cargo cadastrado com sucesso.');
        setPositionName('');
        setPositionDescription('');
        setTypePosition('');
      } else {
        setErrorMessage('Erro ao cadastrar o cargo. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Erro ao cadastrar o cargo. Por favor, tente novamente.');
    }
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar novo Cargo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              className='mb-3'
              type="text"
              placeholder="Nome do Cargo"
              value={PositionName}
              onChange={(e) => setPositionName(e.target.value)}
              required
            />
            <Form.Control
              className='mb-3'
              type="text"
              placeholder="Descrição do Cargo"
              value={PositionDescription}
              onChange={(e) => setPositionDescription(e.target.value)}
              required
            />
            <Form.Control
              type="text"
              placeholder="Tipo do Cargo"
              value={TypePosition}
              onChange={(e) => setTypePosition(e.target.value)}
              required
            />
          </Form.Group>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={onClose} className="me-2">
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PositionModal;
