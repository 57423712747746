import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface UserProfileData {
  name: string;
  email: string;
}

interface UserProfileProps {
  children: (data: UserProfileData) => React.ReactNode;
}

const UserProfile: React.FC<UserProfileProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserProfileData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('https://webservices.gfttech.com.br/api/v1/usuario/perfil-usuario', { headers });

        if (response.status === 200) {
          const userData: UserProfileData = {
            name: response.data.Usuario.userName,
            email: response.data.email,
          };
          setUserData(userData);
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <>
      {loading && <div></div>}
      {!loading && userData && children(userData)}
    </>
  );
};

export default UserProfile;
