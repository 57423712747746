import { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalendarCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from './Production.module.scss';

interface ProductionProps {
  prodMesAtual: number;
  prodMesAnterior: number;
  quantidade_dias_sem_digitar: any;
  codPartner: any;
}

interface ConvenioData {
  dsc_entidade: string;
  producao: number;
}

interface BancoData {
  nom_banco: string;
  producao: number;
}

interface OperacaoData {
  dsc_tipo_proposta_emprestimo: string;
  producao: number;
}

export default function Production({ prodMesAtual, prodMesAnterior, quantidade_dias_sem_digitar, codPartner }: ProductionProps) {
  const [convenioData, setConvenioData] = useState<ConvenioData[]>([]);
  const [bancoData, setBancoData] = useState<BancoData[]>([]);
  const [operacaoData, setOperacaoData] = useState<OperacaoData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);

    const fetchConvenioData = fetch(`https://bi.gfttech.com.br/api/v1/atendimento/producaoConvenioEntidade?codProdutorVenda=${codPartner}`)
      .then(response => response.json())
      .then(data => setConvenioData(data));

    const fetchBancoData = fetch(`https://bi.gfttech.com.br/api/v1/atendimento/producaoBanco?codProdutorVenda=${codPartner}`)
      .then(response => response.json())
      .then(data => setBancoData(data));

    const fetchOperacaoData = fetch(`https://bi.gfttech.com.br/api/v1/atendimento/producaoOperacao?codProdutorVenda=${codPartner}`)
      .then(response => response.json())
      .then(data => setOperacaoData(data));

    Promise.all([fetchConvenioData, fetchBancoData, fetchOperacaoData])
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, [codPartner]);

  const getDaysWithoutTypingColor = (days: number | null) => {
    if (days === null) return '#6c757d';
    if (days === 0) return '#57A517';
    if (days > 0 && days <= 3) return '#6832E0';
    if (days > 3 && days <= 7) return '#57A517';
    if (days > 7) return '#DC2626';
    return '#6832E0';
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  return (
    <div className={`${styles.productionSection} mb-5`}>
      <h2 className={`display-6 mt-5 mb-5 ${styles.underlineTitle}`}>Produção</h2>
      <Row className="mb-4">
        <Col>
          <Card className="mb-3" style={{backgroundColor: '#6832E0', color: 'white'}}>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div>
                <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                <Card.Title className={`${styles.cardTitle} d-inline`}>Mês Atual</Card.Title>
              </div>
              <Card.Text>R$ {prodMesAtual.toLocaleString('pt-BR')}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-3" style={{backgroundColor: '#6832E0', color: 'white'}}>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div>
                <FontAwesomeIcon icon={faCalendarCheck} className="me-2" />
                <Card.Title className={`${styles.cardTitle} d-inline`}>Mês Anterior</Card.Title>
              </div>
              <Card.Text>R$ {prodMesAnterior.toLocaleString('pt-BR')}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-3" style={{backgroundColor: getDaysWithoutTypingColor(quantidade_dias_sem_digitar), color: 'white'}}>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div>
                <FontAwesomeIcon icon={faClock} className="me-2" />
                <Card.Title className={`${styles.cardTitle} d-inline`}>Dias Sem Digitar</Card.Title>
              </div>
              <Card.Text>{quantidade_dias_sem_digitar !== null ? quantidade_dias_sem_digitar : 'NUNCA DIGITOU'}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {isLoading ? (
        <Spinner animation="border" />
      ) : isError ? (
        <Alert className={styles.textAlerta} variant="secondary">Parceiro não possui produção a ser exibida.</Alert>
      ) : (
        <Row>
          {/* PRODUCAO POR CONVENIO */}
          <Col md={4}>
            <Card>
              <Card.Header> 
                <h4 className={`${styles.cardTitle} d-inline`}>Produção Por Convênio</h4>
              </Card.Header>
              <Card.Body style={{ height: '300px', overflowY: 'auto' }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Convênio</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {convenioData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.dsc_entidade}</td>
                        <td>{formatCurrency(item.producao)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          {/* PRODUCAO POR BANCO */}
          <Col md={4}>
            <Card>
              <Card.Header> 
                <h4 className={`${styles.cardTitle} d-inline`}>Produção Por Banco</h4>
              </Card.Header>
              <Card.Body style={{ height: '300px', overflowY: 'auto' }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bancoData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.nom_banco}</td>
                        <td>{formatCurrency(item.producao)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          {/* PRODUCAO POR OPERACAO */}
          <Col md={4}>
            <Card>
              <Card.Header> 
                <h4 className={`${styles.cardTitle} d-inline`}>Produção Por Operação</h4>
              </Card.Header>
              <Card.Body style={{ height: '300px', overflowY: 'auto' }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Operação</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operacaoData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.dsc_tipo_proposta_emprestimo}</td>
                        <td>{formatCurrency(item.producao)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}