import { useEffect, useState } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';
import styles from './Retention.module.scss';

interface RetentionData {
  num_proposta: string;
  val_bruto: number;
  nom_banco: string;
  data_limite: string;
  nu: string;
  prazo_total: string;
  pmt_em_aberto: string;
  pmt_pagas: string;
  banco_solicitante: string;
  telefone: string;
}

export default function Retention({ codPartner }: { codPartner: any }) {
  const [retentionData, setRetentionData] = useState<RetentionData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    fetch(`https://bi.gfttech.com.br/api/v1/atendimento/retencaoNotificada?codProdutorVenda=${codPartner}`)
      .then(response => {
        if (response.status === 404) {
          throw new Error("404");
        }
        return response.json();
      })
      .then(data => {
        setRetentionData(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (error.message === "404") {
          setIsError(true);
        }
        setIsLoading(false);
      });
  }, [codPartner]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  return (
    <div className={`${styles.retentionSection} mb-5`}>
      <h3 className={`${styles.underlineTitle} mt-5 mb-5`}>Retenção de Carteira</h3>
      {isLoading ? (
        <Spinner animation="border" />
      ) : isError ? (
        <Alert variant="secondary" className={styles.textAlerta}>
          Parceiro não possui retenções de carteira a serem notificadas.
        </Alert>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Proposta</th>
              <th>Valor Bruto</th>
              <th>Banco</th>
              <th>Data Limite</th>
              <th>NU</th>
              <th>Prazo</th>
              <th>Aberto</th>
              <th>Pagas</th>
              <th>Banco Solicitante</th>
              <th>Telefone</th>
            </tr>
          </thead>
          <tbody>
            {retentionData.map((item, index) => (
              <tr key={index}>
                <td>{item.num_proposta}</td>
                <td>{formatCurrency(item.val_bruto)}</td>
                <td>{item.nom_banco}</td>
                <td>{new Date(item.data_limite).toLocaleDateString()}</td>
                <td>{item.nu}</td>
                <td>{item.prazo_total}</td>
                <td>{item.pmt_em_aberto}</td>
                <td>{item.pmt_pagas}</td>
                <td>{item.banco_solicitante}</td>
                <td>{item.telefone}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}