import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import DepartmentConfirmExclude from '../DepartmentComponent/DepartmentConfirmExclude';
import DepartmentEditModal from '../DepartmentComponent/DepartmentEdit'; // Importe o componente DepartmentEditModal
import Pagination from '../../../Pagination';

interface Department {
  department_id: number;
  department_name: string;
}

interface DepartmentListProps {
  onReload: () => void;
}

const DepartmentList: React.FC<DepartmentListProps> = ({ onReload }) => {
  const [selectedDepartmentIdForEdit, setSelectedDepartmentIdForEdit] = useState<number | null>(null);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number | null>(null);
  const [Departments, setDepartments] = useState<Department[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', 'https://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/buscar-todos', null, headers);

        if (response.status === 200) {
          setDepartments(response.result);
          console.log(response.result)
        } else {
          setError('Erro ao buscar os Departamentos. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os Departamentos. Por favor, tente novamente.');
      }
      finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const handleShowModal = (DepartmentId: number) => {
    setSelectedDepartmentIdForEdit(DepartmentId);
  };

  const handleCloseModal = () => {
    setSelectedDepartmentIdForEdit(null);
    setError('');
    updateDepartmentList();
  };

  const handleDepartmentDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedDepartmentId) {
        throw new Error('Token or Department ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `https://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/deletar/${selectedDepartmentId}`, null, headers);

      if (response.status === 200) {
        // remover o Departamento excluído do estado de Departamentos
        setDepartments(Departments.filter(Department => Department.department_id !== selectedDepartmentId));
        handleCloseModal();
      } else {

        // verificando se a mensagem de erro é realmente um erro da API ou se é apenas uma condição inesperada
        if (response.result && response.result.message) {
          setError(response.result.message);
        } else {
          setError('Erro ao excluir o Departamento. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir o Departamento. Por favor, tente novamente.');
    }
  };

  const handleDepartmentUpdate = async () => {
    // Lógica para atualização do Departamento
  };

  const updateDepartmentList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'https://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/buscar-todos', null, headers);

      if (response.status === 200) {
        setDepartments(response.result);
      } else {
        setError('Erro ao buscar os Departamentos. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os Departamentos. Por favor, tente novamente.');
    }

  };

  const handlePageChange = (page: number) => {  
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDepartments = Array.isArray(Departments) ? Departments.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <Col sm={12} md={10}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Departamento</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {currentDepartments.map((Department) => (
                <tr key={Department.department_id}>
                  <td>{Department.department_name}</td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-primary" onClick={() => handleShowModal(Department.department_id)}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </button>
                  </td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-danger" onClick={() => setSelectedDepartmentId(Department.department_id)}>
                      <FontAwesomeIcon icon={faTrash}/>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={Departments.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {selectedDepartmentId && (
        <DepartmentConfirmExclude DepartmentId={selectedDepartmentId} onClose={() => { setSelectedDepartmentId(null); updateDepartmentList(); }} onDelete={handleDepartmentDelete} error={error} />
      )}
      
      {/* Adicionando o modal de edição */}
      {selectedDepartmentIdForEdit && (
        <DepartmentEditModal DepartmentId={selectedDepartmentIdForEdit} onClose={handleCloseModal} onError={setError} onUpdate={handleDepartmentUpdate} />
      )}
    </Col>
  );
};

export default DepartmentList;