import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table, Button, Alert, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../requestClient/request-cliente';
import Pagination from '../../Pagination';
import { Link } from 'react-router-dom';
import ConfirmExcludeEmployee from '../../Register/ConfirmExcludeEmployee';
import DataExportExcel from '../../Exports/DataExportExcel';
import FilterEmployee from '../../Register/Filters/FilterEmployee';
import DataExportPdf from '../../Exports/DataExportPdf';

interface Access {
  department_name: string;
  role_name: string;
}

interface Employee {
  employee_id: number;
  user_name: string;
  trading_name: string;
  position_name: string;
  acesso: Access[];
}

interface EmployeeListProps {
  onReload: () => void;
}

const EmployeeList: React.FC<EmployeeListProps> = ({ onReload }) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showConfirmExcludeModal, setShowConfirmExcludeModal] = useState<boolean>(false);
  const [selectedEmployeeForExclude, setSelectedEmployeeForExclude] = useState<number | null>(null);
  const [exclusionMessage, setExclusionMessage] = useState<string>('');
  const [filters, setFilters] = useState({ department: '', position: '', role: '', name: '' });

  const itemsPerPage = 7;

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'https://webservices.gfttech.com.br/api/v1/funcionario/buscar-todos', null, headers);

      if (response.status === 200) {
        setEmployees(response.result);
      } else {
        setError('Erro ao buscar os funcionários. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os funcionários. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeDelete = (employeeId: number) => {
    setSelectedEmployeeForExclude(employeeId);
    setExclusionMessage('Tem certeza que deseja excluir este funcionário?');
    setShowConfirmExcludeModal(true);
  };

  const handleConfirmExclude = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedEmployeeForExclude) {
        throw new Error('Token or Employee ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `https://webservices.gfttech.com.br/api/v1/funcionario/deletar/${selectedEmployeeForExclude}`, null, headers);

      if (response.status === 204) {
        setSuccessMessage('Funcionário excluído com sucesso.');
        setShowConfirmExcludeModal(false);
        setSelectedEmployeeForExclude(null);
        fetchEmployees();
      } else {
        setError('Erro ao excluir o funcionário. Por favor, tente novamente.');
        setShowConfirmExcludeModal(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir o funcionário. Por favor, tente novamente.');
      setShowConfirmExcludeModal(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (newFilters: { department: string; position: string; role: string; name: string }) => {
    setFilters(newFilters);
    // Optionally, you can re-fetch or filter employees based on new filters
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEmployees = Array.isArray(employees) ? employees.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <>
      <Col sm={12} md={10} className="mx-auto">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            {/* Row for the filters and Export button */}
            <Row className="mb-3 align-items-center">
              <Col md={12}>
                <FilterEmployee onFilterChange={handleFilterChange} />
              </Col>
            
            </Row>

            <Row>
              <Col className="text-end">
                <DataExportPdf/>
                <DataExportExcel />
              </Col>
            </Row>

            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Funcionário</th>
                  <th>Filial</th>
                  <th>Departamento</th>
                  <th>Cargo</th>
                  <th>Função</th>
                  <th>Editar</th>
                  <th>Excluir</th>
                </tr>
              </thead>
              <tbody>
                {currentEmployees.map((employee) => (
                  <tr key={employee.employee_id}>
                    <td>{employee.user_name}</td>
                    <td>{employee.trading_name}</td>
                    <td>{employee.acesso.map((a) => a.department_name).join(', ')}</td>
                    <td>{employee.position_name}</td>
                    <td>{employee.acesso.map((a) => a.role_name).join(', ')}</td>
                    <td style={{ width: '7%' }}>
                      <Link to={`/AccessManagementEmployee/Edit/${employee.employee_id}`} className="btn btn-primary">
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                    </td>
                    <td style={{ width: '7%' }}>
                      <Button variant="danger" onClick={() => handleEmployeeDelete(employee.employee_id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              totalItems={employees.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </>
        )}
        <ConfirmExcludeEmployee
          show={showConfirmExcludeModal}
          handleClose={() => setShowConfirmExcludeModal(false)}
          handleConfirm={handleConfirmExclude}
          employeeId={selectedEmployeeForExclude}
          onReload={onReload}
          message={exclusionMessage}
        />
      </Col>
    </>
  );
};

export default EmployeeList;
