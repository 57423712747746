import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import PositionConfirmExclude from '../PositionComponent/PositionConfirmExclude';
import PositionEditModal from '../PositionComponent/PositionEdit'; // Importe o componente PositionEditModal
import Pagination from '../../../Pagination';

interface Position {
  position_id: number;
  position_name: string;
}

interface PositionListProps {
  onReload: () => void;
}

const PositionList: React.FC<PositionListProps> = ({ onReload }) => {
  const [selectedPositionIdForEdit, setSelectedPositionIdForEdit] = useState<number | null>(null);
  const [selectedPositionId, setSelectedPositionId] = useState<number | null>(null);
  const [Positions, setPositions] = useState<Position[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', 'https://webservices.gfttech.com.br/api/v1/posicao/buscar-todas', null, headers);

        if (response.status === 200) {
          setPositions(response.result);
        } else {
          setError('Erro ao buscar os Cargos. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os Cargos. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchPositions();
  }, []);

  const handleShowModal = (PositionId: number) => {
    setSelectedPositionIdForEdit(PositionId);
  };

  const handleCloseModal = () => {
    setSelectedPositionIdForEdit(null);
    setError('');
    updatePositionList();
  };

  const handlePositionDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedPositionId) {
        throw new Error('Token or Position ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `https://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/deletar/${selectedPositionId}`, null, headers);

      if (response.status === 200) {
        // remover o Cargo excluído do estado de Cargos
        setPositions(Positions.filter(Position => Position.position_id !== selectedPositionId));
        handleCloseModal();
      } else {
        // verificando se a mensagem de erro é realmente um erro da API ou se é apenas uma condição inesperada
        if (response.result && response.result.message) {
          setError(response.result.message);
        } else {
          setError('Erro ao excluir o Cargo. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir o Cargo. Por favor, tente novamente.');
    }
  };

  const handlePositionUpdate = async () => {
    updatePositionList();
  };

  const updatePositionList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'https://webservices.gfttech.com.br/api/v1/posicao/buscar-todas', null, headers);

      if (response.status === 200) {
        setPositions(response.result);
      } else {
        setError('Erro ao buscar os Cargos. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os Cargos. Por favor, tente novamente.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPositions = Array.isArray(Positions) ? Positions.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <Col sm={12} md={10}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Cargo</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {currentPositions.map((Position) => (
                <tr key={Position.position_id}>
                  <td>{Position.position_name}</td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-primary" onClick={() => handleShowModal(Position.position_id)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-danger" onClick={() => setSelectedPositionId(Position.position_id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={Positions.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {selectedPositionId && (
        <PositionConfirmExclude PositionId={selectedPositionId} onClose={() => { setSelectedPositionId(null); updatePositionList(); }} onDelete={handlePositionDelete} error={error} />
      )}

      {/* Adicionando o modal de edição */}
      {selectedPositionIdForEdit && (
        <PositionEditModal PositionId={selectedPositionIdForEdit} onClose={handleCloseModal} onError={setError} onUpdate={handlePositionUpdate} />
      )}
    </Col>
  );
};

export default PositionList;