import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PositionConfirmExcludeProps {
  PositionId: number | null;
  onClose: () => void;
  onDelete: () => void;
  error: string;
}

const PositionConfirmExclude: React.FC<PositionConfirmExcludeProps> = ({ PositionId, onClose, onDelete, error }) => {
  const [isDeleted, setIsDeleted] = useState(false); // state para controlar se o Cargo foi excluído com sucesso
  const [deletionError, setDeletionError] = useState<string>(''); // state para controlar erros de exclusão

  if (!PositionId) return null; // se PositionId for nulo, não preciso renderizar o modal

  // Função para enviar a solicitação de exclusão do Cargo
  const deletePosition = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Faz a requisição DELETE para o endpoint correspondente com os headers adequados
      const response = await fetch(`https://webservices.gfttech.com.br/api/v1/posicao/deletar/${PositionId}`, {
        method: 'DELETE',
        headers: headers,
      });

      // Verifica o status da resposta para determinar se a exclusão foi bem-sucedida
      if (response.ok) {
        setIsDeleted(true);
        // Chama a função onDelete para atualizar o estado do componente pai, se necessário
        onDelete();
      } else {
        throw new Error('Erro ao excluir o Cargo');
      }
    } catch (error) {
      // Se ocorrer algum erro, você pode lidar com ele aqui
      console.error('Erro ao excluir o Cargo:', error);
      console.log(Response)
      setDeletionError('Erro ao excluir o Cargo. Por favor, tente novamente.');
    }
  };

  // Função para fechar o modal
  const handleClose = () => {
    onClose();
  };

  // se o Cargo foi excluído com sucesso, exibir mensagem e ocultar botões
  if (isDeleted) {
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cargo excluído com sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir este Cargo?
          {deletionError && (
            <div className="alert alert-danger mt-3" role="alert">
              {deletionError}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deletePosition}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default PositionConfirmExclude;
